import { superFetch } from '../utils/superFetch';

const baseUrl = process.env.REACT_APP_API_URL;

const getAvailableGifts = async () => {
  const url = `${baseUrl}/gifts/available`;

  const response = await superFetch.get(url);

  const availableGifts = await response.json();

  console.log('available gifts', availableGifts);
  return availableGifts;
};

const chooseGift = async (giftId) => {
  const url = `${baseUrl}/gifts/choose`;

  const response = await superFetch.put(url, { giftId });

  if (response.status !== 204 && response.status !== 401) {
    const responseBody = await response.json();
    console.log('Error choosing gift', responseBody);
    throw new Error(`Erro escolhendo o presente: ${responseBody.errorMessage}`);
  }

  return response.status === 204;
};

export { getAvailableGifts, chooseGift };
