import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography } from '@mui/material';

export default function WelcomeDialog({ handleClose, isOpen, hideDialog }) {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Seja bem vindo à lista de presentes do chá de panela!</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} component="span" spacing={4}>
            <Typography variant="body1" sx={{ fontSize: 18 }} component="span">
              Fique à vontade para escolher o presente que se sentir mais confortável e compra-lo em um lugar de sua
              confiança.
            </Typography>
            <Typography variant="h6" component="span">
              Nos vemos no dia 18 de junho!
            </Typography>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="error" sx={{ textTransform: 'none' }}>
          Não mostrar novamente
        </Button>
        <Button onClick={handleClose} variant="contained" sx={{ textTransform: 'none' }}>
          Estarei lá!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
