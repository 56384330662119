import { authEventEmitter } from './authEventEmitter';
import { getToken, checkIfTokenExpired } from './auth';

class SuperFetch {
  constructor() {
    this._headers = {};
  }

  async get(url) {
    this._prepareHeaders();
    const response = await this._executeFetch(url, 'GET');

    return response;
  }

  async post(url, body) {
    this._prepareHeaders();
    const response = await this._executeFetch(url, 'POST', body);

    return response;
  }

  async put(url, body) {
    this._prepareHeaders();
    const response = await this._executeFetch(url, 'PUT', body);

    return response;
  }

  _prepareHeaders() {
    this._headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    };
  }

  async _executeFetch(url, method, body) {
    const options = {
      method,
      body: JSON.stringify(body),
      headers: this._headers,
    };

    const response = await fetch(url, options);

    if (response.status === 401) {
      authEventEmitter.emit('logout');
    }

    return response;
  }
}

export const superFetch = new SuperFetch();
