import PropTypes from 'prop-types';
import { Box, Card, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, handleClick }) {
  const { title, amount, imageUrl } = product;

  return (
    <Card sx={{ width: '100%' }}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <ProductImgStyle alt={title} src={imageUrl} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">Quantidade disponível: {amount}</Typography>

        <Button variant="contained" style={{ textTransform: 'none', fontSize: 14 }} onClick={handleClick}>
          Quero dar esse presente!
        </Button>
      </Stack>
    </Card>
  );
}
