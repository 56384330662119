const baseUrl = process.env.REACT_APP_API_URL; // || 'http://localhost:3001';

const guestLogin = async ({ name, password }) => {
  const url = `${baseUrl}/guests/login`;

  const body = JSON.stringify({ name, password });
  const headers = { 'Content-Type': 'application/json' };

  const response = await fetch(url, { method: 'POST', body, headers });
  const responseBody = await response.json();

  if (response.status !== 200) {
    throw new Error(`Erro no login: ${responseBody.errorMessage}`);
  }

  const { token, guestName } = responseBody;

  return { token, guestName };
};

export { guestLogin };
