import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import ConfirmGiftDialog from '../../../components/ConfirmGiftDialog';
import { chooseGift } from '../../../api/gifts.api';
import CongratulationsDialog from '../../../components/CongratulationsDialog';
import WelcomeDialog from '../../../components/WelcomeDialog';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array,
  refreshList: PropTypes.func,
};

export default function ProductList({ products = [], refreshList, ...other }) {
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isCongratsDialogOpen, setCongratsDialogOpen] = useState(false);

  const shouldShowWelcomeDialog = !localStorage.getItem('hideWelcome@marriage-gifts');

  const [isWelcomeDialogOpen, setWelcomeDialogOpen] = useState(shouldShowWelcomeDialog);
  const [selectedGift, setSelectedGift] = useState({});

  const handleConfirm = async () => {
    try {
      const success = await chooseGift(selectedGift._id);

      if (success) {
        await refreshList();
        setConfirmDialogOpen(false);
        setCongratsDialogOpen(true);
      }
    } catch (error) {
      setConfirmDialogOpen(false);
      console.log('Error on chooseGift', error);
      alert(error.message);
    }
  };

  const handleSelectGift = (product) => () => {
    setSelectedGift(product);
    setConfirmDialogOpen(true);
  };

  const handleCongratsClose = () => {
    setSelectedGift({});
    setCongratsDialogOpen(false);
  };

  const handleHideDialog = () => {
    localStorage.setItem('hideWelcome@marriage-gifts', true);
    setWelcomeDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={3} {...other}>
        {products.map((product) => (
          <Grid key={product._id} item sm={12} md={4} sx={{ width: 1 }}>
            <ShopProductCard product={product} handleClick={handleSelectGift(product)} />
          </Grid>
        ))}
      </Grid>
      <ConfirmGiftDialog
        isOpen={isConfirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        gift={selectedGift}
        handleConfirm={handleConfirm}
      />
      <CongratulationsDialog isOpen={isCongratsDialogOpen} gift={selectedGift} handleClose={handleCongratsClose} />
      <WelcomeDialog
        isOpen={isWelcomeDialogOpen}
        handleClose={() => setWelcomeDialogOpen(false)}
        hideDialog={handleHideDialog}
      />
    </>
  );
}
