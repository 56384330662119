class EventEmitter {
  constructor() {
    this.events = [];
    this.eventToListenerDict = {};
  }

  static _validateInput(event, listener) {
    if (!event || !listener) {
      throw new Error('event and listener are mandatory');
    }

    if (typeof event !== 'string') {
      throw new Error('event must be a string');
    }

    if (typeof listener !== 'function') {
      throw new Error('listener must be a function');
    }
  }

  addListener(event, listener) {
    EventEmitter._validateInput(event, listener);

    this.events.push(event);

    if (this.eventToListenerDict[event] === undefined) {
      this.eventToListenerDict[event] = [];
    }

    this.eventToListenerDict[event].push(listener);
  }

  removeListener(event, listener) {
    EventEmitter._validateInput(event, listener);

    if (this.eventToListenerDict[event]) {
      const index = this.eventToListenerDict[event].indexOf(listener);

      this.eventToListenerDict.splice(index, 1);

      if (this.eventToListenerDict[event].length === 0) {
        delete this.eventToListenerDict[event];
      }

      return true;
    }

    return false;
  }

  emit(event) {
    if (!event || typeof event !== 'string') {
      throw new Error(`Invalid event: ${event}`);
    }

    if (this.eventToListenerDict[event]) {
      this.eventToListenerDict[event].forEach((listener) => listener());
    }
  }
}

export const authEventEmitter = new EventEmitter();
