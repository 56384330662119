import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from './Iconify';

const ProductImgStyle = styled('img')({
  top: 0,
  height: '300px',
  width: '100%',
  maxWidth: '300px',
  objectFit: 'contain',
});

export default function CongratulationsDialog({ isOpen, handleClose, gift }) {
  const { title } = gift;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>Obrigado por escolher o presente "{title}"!</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} component="span" spacing={4}>
            <Typography variant="body1" sx={{ fontSize: 17 }} component="span">
              Já anotamos tudo por aqui e amamos sua escolha! {'<3'}
            </Typography>
            <Typography variant="h6" component="span">
              Não se esqueça! Nossa festa será dia 18/06 e contamos muito com sua presença!{' '}
              <Iconify icon="ant-design:heart-filled" width={24} height={24} sx={{ color: 'red' }} />
            </Typography>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Estarei lá!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
