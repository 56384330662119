import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authEventEmitter } from './authEventEmitter';

export const TOKEN_KEY = 'token@marriage-gifts';
export const USER_NAME_KEY = 'userName@marriage-gifts';

export const logout = (navigateToLogin) => {
  localStorage.removeItem(TOKEN_KEY);
  navigateToLogin();
};

export const saveToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const saveUseName = (userName) => {
  localStorage.setItem(USER_NAME_KEY, userName);
};

export const getUserName = () => localStorage.getItem(USER_NAME_KEY);

const getExpirationDateInMsFromToken = (jwtToken) => {
  try {
    if (!jwtToken) {
      return null;
    }

    const [, payload] = jwtToken.split('.');
    const decodedStringPayload = window.atob(payload);
    const { exp: expirationDate } = JSON.parse(decodedStringPayload);

    return expirationDate * 1000;
  } catch (error) {
    console.log('Error getting expiration date, error:', error);
    return null;
  }
};

export const checkIfTokenExpired = () => {
  const token = getToken();
  const expirationDateInMs = getExpirationDateInMsFromToken(token);

  if (!expirationDateInMs) {
    return true;
  }

  return Date.now() > expirationDateInMs;
};

export const getTokenData = () => {
  const token = getToken();
  const isExpired = checkIfTokenExpired();

  return { token, isExpired };
};

export const useAuth = () => {
  const [token, setToken] = useState(getToken());
  const [isExpired, setExpired] = useState(checkIfTokenExpired());
  const navigate = useNavigate();

  const login = (jwt) => {
    setToken(jwt);
    setExpired(false);
  };

  useEffect(() => {
    setToken(getToken());
    setExpired(checkIfTokenExpired());

    const onLogout = () => {
      console.log('emitiu logout!');
      const navigateToLogin = () => navigate('/login');
      logout(navigateToLogin);
    };
    authEventEmitter.addListener('logout', onLogout);

    return () => {
      authEventEmitter.removeListener('logout', onLogout);
    };
  }, []);

  return { token, isExpired, login };
};
