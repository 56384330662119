import { useState, useEffect, useCallback } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { getAvailableGifts } from '../api/gifts.api';
import Page from '../components/Page';
import { ProductSort, ProductList, ProductFilterSidebar } from '../sections/@dashboard/products';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const [gifts, setGifts] = useState([]);

  const loadGifts = useCallback(async () => {
    const availableGifts = await getAvailableGifts();
    setGifts(availableGifts);
  }, [getAvailableGifts]);

  useEffect(() => {
    loadGifts();
  }, []);

  return (
    <Page title="Chá de Panela - Letícia e João Victor">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Lista de presentes do chá de panela
        </Typography>
        <ProductList products={gifts} refreshList={loadGifts} />
      </Container>
    </Page>
  );
}
