import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { guestLogin } from '../../../api/guests.api';
import { saveToken, saveUseName } from '../../../utils/auth';

// ----------------------------------------------------------------------

export default function LoginForm({ login }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório!'),
    password: Yup.string().required('A palavra secreta é obrigatória!'),
  });

  const saveAuthAndNavigate = (token, guestName) => {
    login(token);
    saveToken(token);
    saveUseName(guestName);
    navigate('/presentes');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: ({ name, password }, actions) => {
      guestLogin({ name, password })
        .then(({ token, guestName }) => saveAuthAndNavigate(token, guestName))
        .catch((error) => {
          alert(error.message);
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ my: 2 }}>
          <TextField
            fullWidth
            type="text"
            label="Seu primeiro nome"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          {/* <TextField
            fullWidth
            type="tel"
            label="Sua palavra secreta"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          /> */}

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Sua palavra secreta"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Entrar
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
