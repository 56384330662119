import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import { getTokenData, useAuth } from './utils/auth';

// ----------------------------------------------------------------------
export default function Router() {
  const { token, isExpired, login } = useAuth();

  const routes = [
    { path: '/', element: <Navigate to={token && !isExpired ? '/presentes' : '/login'} /> },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login login={login} /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  if (token && !isExpired) {
    routes.push({ path: '/', element: <DashboardLayout />, children: [{ path: 'presentes', element: <Products /> }] });
  } else {
    routes.push({ path: '/presentes', element: <Navigate to="/login" /> });
  }

  return useRoutes(routes);
}
