// component
import { logout } from '../../utils/auth';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'presentes',
    path: '/presentes',
    icon: getIcon('ant-design:gift-filled'),
  },
  {
    title: 'sair',
    action: (navigateToLogin) => () => logout(navigateToLogin),
    icon: getIcon('ant-design:logout-outlined'),
  },
];

export default navConfig;
