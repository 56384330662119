import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Iconify from './Iconify';

const ProductImgStyle = styled('img')({
  top: 0,
  height: '300px',
  width: '100%',
  maxWidth: '400px',
  objectFit: 'contain',
});

export default function ConfirmGiftDialog({ isOpen, handleClose, handleConfirm, gift }) {
  const { title, imageUrl, link } = gift;
  const [isLoading, setLoading] = useState(false);

  const openSuggestionPage = () => {
    window.open(link, '_blank');
  };

  const handleAndLockConfirm = async () => {
    setLoading(true);
    await handleConfirm();
    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>Confirmar presente "{title}"</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Grid spacing={0} container direction="row" justifyContent="center">
            <Grid item md={6} sm={12} alignItems="center">
              <ProductImgStyle
                alt={title}
                src={imageUrl}
                sx={{ marginY: 2, marginLeft: 'auto', marginRight: 'auto' }}
              />
            </Grid>
            <Grid container item md={6} sm={12}>
              <Grid container item direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="body1" sx={{ fontSize: 17 }}>
                    Caso você ainda não tenha comprado o presente, nós separamos uma sugestão para te ajudar, para ver o
                    site clique no botão abaixo:
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={openSuggestionPage}
                    sx={{ height: 50, fontSize: 16, width: 200, display: 'block' }}
                  >
                    Ver sugestão
                    <Iconify icon="icomoon-free:new-tab" width={18} height={18} sx={{ marginLeft: 1 }} />
                  </Button>
                </Grid>
                <Grid item container direction="row" alignItems="center">
                  <Grid item sm={1}>
                    <Iconify icon="bi:info-circle-fill" width={24} height={24} />
                  </Grid>
                  <Grid item sm={11}>
                    <Typography variant="body2">
                      Para confirmar a escolha desse presenteclique na opção "Confirmar" ou escolha "Cancelar" para
                      retornar à página anterior.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancelar
        </Button>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleAndLockConfirm}>
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
